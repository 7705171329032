#discussion-container{
    .text-column{
        padding-top: 80px;
        .text-container{
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;   
            &::-webkit-scrollbar {
                width: 0px!important;  /* Remove scrollbar space */
                background: transparent!important;  /* Optional: just make scrollbar invisible */
            }
            overflow-y: scroll;
            #discussion-data-tab{
                box-shadow: 
                0 8px 17px 2px rgba(0,0,0,0.14), 
                0 3px 14px 2px rgba(0,0,0,0.12), 
                0 5px 5px -3px rgba(0,0,0,0.2);
                border-radius: 5px;
                .nav.nav-pills{
                    a{
                        background-color: #ffffff;
                        color: #121212;
                        &.active{
                            background-color: #119DA4;
                            color: #ffffff;
                        }
                    }
                }
                .discussion-event{
                    box-shadow: 
                    0 2px 2px 0 rgba(0,0,0,0.14), 
                    0 3px 1px -2px rgba(0,0,0,0.12), 
                    0 1px 5px 0 rgba(0,0,0,0.2);
                    .discussion-resources{
                        font-size: 13px;
                        line-height: 14px;
                        .discussion-resource{
                            font-size: 12px;
                            line-height: 13px;
                            a{
                                color: #0099ff;
                                text-decoration: none;
                                &:hover{
                                    color: #2b2c92;
                                }
                            }
                        }
                        
                    }
                    .date-div{
                        background-color: #119DA4;
                        border-radius: 50%;
                        color: #ffffff;
                        .date-date{
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 24px;
                        }
                        .date-month-year{
                            font-size: 12px;
                            line-height: 12px;
                            white-space: nowrap;
                        }
                    }
                }
            }
            .floating-button{
                font-size: 24px;
                color: #4557bb;
                bottom: 3%;
                box-shadow: 2px 2px 3px #999;
                overflow: hidden;
                left: 3%;
            }
        }  
    }
    .image-column{
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #ffffff;
        font-size: 20px;
        line-height: 20px;
        font-weight: 900;
        .os-text{
            position: absolute;
            top: calc(80px + 5%);
            color: #ffffff;
            left: 5%;
        }
        .deep-text{
            text-align: right;
            position: absolute;
            bottom: 5%;
            right: 5%;
        }
    }
    
    .icon-text{
        .icon-container{
            font-size: 52px;
            color: #0099ff;
            
        }
        text-transform: uppercase;
        font-size: 12px;
        color: #454545;
        // border: 2px solid #0099ff;
        //     border-radius: 400px;
    }
}

  